<template>
  <v-container>
    <v-row class="justify-center text-center">
      <v-col cols="12">
        <h2 class="font-weight-bold mt-4 mb-10">Lista de preguntas</h2>

        <div class="justify-end d-flex mb-4">
          <v-dialog persistent width="500" v-model="modal_configs_formulario">
            <ModalConfigsFormulario
              v-if="modal_configs_formulario"
              @cerrar-modal="modal_configs_formulario = false"
            />
          </v-dialog>

          <v-btn
            small
            color="primary"
            class="mr-1"
            @click="modal_configs_formulario = true"
          >
            <v-icon small class="mr-2">mdi-cog</v-icon>
            Configs
          </v-btn>

          <!-- Modal que sirve para filtrar el contenido de la tabla con varios filtros -->
          <v-dialog persistent :width="anchoDialogos">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="primary"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="mr-2">mdi-filter-plus</v-icon>
                Filtro
              </v-btn>
            </template>

            <template v-slot:default="dialogFiltro">
              <v-card class="justify-center text-center">
                <v-card-title class="grey lighten-3 justify-center mb-3">
                  Filtro de preguntas
                </v-card-title>

                <!-- Se especifica el tipo de filtrado y el software evalúa si el usuario ha escogido el
                     filtrado por ítem y por categoría -->
                <v-card-text>
                  <v-form ref="formularioFiltros">
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          :items="tipos_filtro"
                          v-model="filtro.tipo_filtro"
                          label="Tipo de filtrado"
                          return-object
                          outlined
                          @change="showFilterFields()"
                          :rules="[rules.required]"
                        >
                        </v-select>
                      </v-col>

                      <!-- Se especifica la categoría o el ítem por el cual se desea filtrar en caso de que
                         haya escogido estas opciones -->
                      <v-col
                        cols="12"
                        v-if="activarCategorias || activarItems"
                        class="mb-3"
                      >
                        <!-- Select que permite seleccionar la categoría por la cual se va a filtrar -->
                        <v-select
                          v-model="filtro.categoria"
                          :items="categoriasSelect"
                          item-text="categoria_item"
                          item-value="id_categoria_item"
                          label="Selecciona una categoría"
                          outlined
                          v-if="activarCategorias"
                          hide-details
                          :rules="[rules.required]"
                        >
                        </v-select>

                        <!-- Select que permite seleccionar el ítem por el cual se va a filtrar -->
                        <v-select
                          v-model="filtro.item"
                          :items="itemsSelect"
                          item-text="item"
                          item-value="id_item"
                          label="Selecciona un ítem"
                          outlined
                          v-if="activarItems"
                          hide-details
                          :rules="[rules.required]"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>

                <!-- Botones para cerrar el modal o aplicar el filtro-->
                <v-btn
                  color="error"
                  small
                  @click="dialogFiltro.value = false"
                  class="mb-3 mr-1"
                  :disabled="loader_filtro"
                >
                  <v-icon small class="mr-1">mdi-close</v-icon>
                  Cerrar
                </v-btn>

                <v-btn
                  color="success"
                  small
                  class="mb-3 ml-1"
                  @click="
                    filter();
                    dialogFiltro.value = false;
                  "
                  :loading="loader_filtro"
                >
                  <v-icon small class="mr-1">mdi-filter-check</v-icon>
                  Aplicar
                </v-btn>
              </v-card>
            </template>
          </v-dialog>

          <!-- Modal que permite añadir una pregunta a la lista. Dentro de la misma se pueden crear ítems
          o categorías faltantes. -->

          <v-dialog persistent :width="anchoDialogos">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="success" v-bind="attrs" v-on="on">
                <v-icon small class="mr-2">mdi-plus-circle</v-icon>
                Añadir
              </v-btn>
            </template>

            <template v-slot:default="dialogAgregar">
              <v-form ref="agregar_pregunta">
                <v-card class="justify-center text-center">
                  <v-card-title class="grey lighten-3 justify-center mb-3">
                    Añadir pregunta
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <!-- Etiqueta sobre la cual dependiendo de si estamos creando una nueva
                        categoría cambiará o no. También hay botones para crear categorías, cancelar
                        la operación o guardar lo creado. -->
                        <label class="text-left justify-start">
                          <span v-if="!activarNuevaCategoriaAnadir"
                            >Selecciona una categoría</span
                          >
                          <span v-else>Crear nueva categoría </span>

                          <v-btn
                            class="ml-1"
                            x-small
                            text
                            color="primary"
                            v-show="!activarNuevaCategoriaAnadir"
                            @click="activarNuevaCategoriaAnadir = true"
                          >
                            <v-icon x-small>mdi-plus</v-icon>
                            Crear
                          </v-btn>
                          <v-btn
                            class="ml-1"
                            x-small
                            text
                            color="error"
                            v-show="activarNuevaCategoriaAnadir"
                            @click="activarNuevaCategoriaAnadir = false"
                          >
                            <v-icon x-small>mdi-close</v-icon>
                            Cancelar
                          </v-btn>
                          <v-btn
                            class="ml-1"
                            x-small
                            text
                            color="success"
                            v-show="activarNuevaCategoriaAnadir"
                            @click="
                              crearCategoria(
                                formularioCreate.categoria_nueva,
                                formularioCreate.tipo_vehiculo
                              )
                            "
                            :loading="loader_agregar_categoria"
                          >
                            <v-icon x-small>mdi-content-save</v-icon>
                            Guardar
                          </v-btn>
                        </label>

                        <!-- Autocompletado que permite seleccionar entre categorías. Desaparece si estamos
                        creando una categoría. -->
                        <v-autocomplete
                          :items="categoriasSelect"
                          v-if="!activarNuevaCategoriaAnadir"
                          :disabled="activarNuevaCategoriaAnadir"
                          v-model="formularioCreate.categoria"
                          item-text="categoria_item"
                          item-value="id_categoria_item"
                          outlined
                          hide-details
                          hide-no-data
                          placeholder="Selecciona una categoría"
                        >
                        </v-autocomplete>

                        <!-- Formulario el cual permite añadir categorías nuevas. -->
                        <v-form ref="agregar_categoria">
                          <v-row>
                            <v-col cols="6">
                              <v-text-field
                                v-if="activarNuevaCategoriaAnadir"
                                v-model="formularioCreate.categoria_nueva"
                                outlined
                                hide-details
                                placeholder="Escribe una categoría nueva"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-select
                                v-if="activarNuevaCategoriaAnadir"
                                v-model="formularioCreate.tipo_vehiculo"
                                outlined
                                hide-details
                                placeholder="Tipo de vehículo"
                                :items="tipos_vehiculo"
                                item-text="tipo_vehiculo"
                                item-value="id_tipo_vehiculo"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-col>

                      <!-- Etiqueta sobre la cual dependiendo de si estamos creando un nuevo item
                      cambiará o no. También hay botones para crear items, cancelar
                      la operación o guardar lo creado. -->
                      <v-col cols="12">
                        <label class="justify-start text-left">
                          <span v-if="!activarNuevoItemAnadir"
                            >Selecciona un ítem</span
                          >
                          <span v-else>Crear nuevo ítem</span>

                          <v-btn
                            class="ml-1"
                            x-small
                            text
                            color="primary"
                            v-show="!activarNuevoItemAnadir"
                            @click="activarNuevoItemAnadir = true"
                          >
                            <v-icon x-small>mdi-plus</v-icon>
                            Crear
                          </v-btn>

                          <v-btn
                            class="ml-1"
                            x-small
                            text
                            color="error"
                            v-show="activarNuevoItemAnadir"
                            @click="activarNuevoItemAnadir = false"
                          >
                            <v-icon x-small>mdi-close</v-icon>
                            Cancelar
                          </v-btn>

                          <v-btn
                            class="ml-1"
                            x-small
                            text
                            color="success"
                            v-show="activarNuevoItemAnadir"
                            @click="
                              crearItem(
                                formularioCreate.item_nuevo,
                                formularioCreate.codigo,
                                formularioCreate.categoria_item
                              )
                            "
                            :loading="loader_agregar_item"
                          >
                            <v-icon x-small>mdi-content-save</v-icon>
                            Guardar
                          </v-btn>
                        </label>

                        <!-- Autocompletado que permite seleccionar items existentes -->
                        <v-autocomplete
                          :items="itemsSelect"
                          v-if="!activarNuevoItemAnadir"
                          :disabled="activarNuevoItemAnadir"
                          v-model="formularioCreate.item"
                          item-text="item"
                          item-value="id_item"
                          outlined
                          hide-details
                          hide-no-data
                          placeholder="Selecciona un ítem"
                        ></v-autocomplete>

                        <!-- Formulario para agregar items nuevos dependiendo de si se esté haciendo la
                        operación o no. -->
                        <v-form ref="agregar_item">
                          <v-row>
                            <v-col cols="4">
                              <v-text-field
                                v-if="activarNuevoItemAnadir"
                                v-model="formularioCreate.item_nuevo"
                                outlined
                                hide-details
                                placeholder="Escribe un ítem nuevo"
                              >
                              </v-text-field>
                            </v-col>

                            <v-col cols="4">
                              <v-select
                                v-if="activarNuevoItemAnadir"
                                v-model="formularioCreate.categoria_item"
                                outlined
                                hide-details
                                placeholder="Categoría del ítem"
                                :items="categoriasSelect"
                                item-text="categoria_item"
                                item-value="id_categoria_item"
                              >
                              </v-select>
                            </v-col>

                            <v-col cols="4">
                              <v-text-field
                                v-if="activarNuevoItemAnadir"
                                v-model="formularioCreate.codigo"
                                outlined
                                hide-details
                                placeholder="Código del ítem"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-col>

                      <!-- Campo para designar el nombre del método -->
                      <v-col cols="12">
                        <v-text-field
                          label="Nombre del método"
                          v-model="formularioCreate.metodo"
                          outlined
                        >
                        </v-text-field>
                      </v-col>
                      <v-row>
                          <v-col cols="12" class="d-flex justify-center">
                            <label>Frecuentemente no aplica</label>
                          </v-col>
                          <v-col cols="12" class="d-flex justify-center mt-n8">
                            <v-switch
                              v-model="formularioCreate.frecuentemente_no_aplica"
                              :label="
                                formularioCreate.frecuentemente_no_aplica
                                  ? 'Si'
                                  : 'No'
                              "
                            ></v-switch>
                          </v-col>
                        </v-row>
                    </v-row>
                  </v-card-text>

                  <!-- Botones para cerrar el modal o crear el método. -->
                  <v-btn
                    color="error"
                    small
                    @click="dialogAgregar.value = false"
                    class="mb-3 mr-1"
                  >
                    <v-icon small class="mr-1">mdi-close</v-icon>
                    Cerrar
                  </v-btn>

                  <v-btn
                    color="success"
                    small
                    class="mb-3 ml-1"
                    @click="
                      crearMetodo(
                        formularioCreate.metodo,
                        formularioCreate.item,
                        formularioCreate.categoria,
                        formularioCreate.frecuentemente_no_aplica
                      );
                      dialogAgregar.value = false;
                    "
                    :loading="loader_agregar_metodo"
                  >
                    <v-icon small class="mr-1">mdi-check</v-icon>
                    Agregar
                  </v-btn>
                </v-card>
              </v-form>
            </template>
          </v-dialog>
        </div>

        <!-- Barra de búsqueda para la tabla -->
        <div class="justify-center d-flex mb-4">
          <v-text-field
            v-model="buscadorTabla"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
          >
          </v-text-field>
        </div>

        <!-- Tabla en la cual se muestran todos los métodos existentes, con el item a que pertenecen y su
             categoría -->
        <v-data-table
          :header-props="{ sortByText: 'Ordenar por' }"
          :headers="headers"
          :items="datosTabla"
          :search="buscadorTabla"
          class="text-center"
          elevation="0"
          :loading="loader_tabla"
          loading-text="Cargando... Por favor espere"
        >

        <template v-slot:[`item.frecuentemente_no_aplica`]="{ item }">
          {{ item.frecuentemente_no_aplica?'Si':'No' }}
        </template>
          <!-- Modal que edita preguntas ya existentes en la lista. Dentro de este mismo modal se puede
          añadir categorías o items faltantes. -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-dialog persistent :width="anchoDialogos">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  color="primary"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mt-2"
                >
                  <v-icon small class="mr-1">mdi-pencil</v-icon>
                  Editar
                </v-btn>
              </template>

              <template v-slot:default="dialogEditar">
                <v-form ref="editar_pregunta">
                  <v-card class="justify-center text-center">
                    <v-card-title class="grey lighten-3 justify-center mb-3">
                      Editar pregunta
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <!-- Etiqueta sobre la cual dependiendo de si estamos creando una nueva
                          categoría cambiará o no. También hay botones para crear categorías, cancelar
                          la operación o guardar lo creado. -->
                          <label class="text-left justify-start">
                            <span v-if="!activarNuevaCategoriaEditar"
                              >Selecciona una categoría</span
                            >
                            <span v-else>Crear nueva categoría</span>

                            <v-btn
                              class="ml-1"
                              x-small
                              text
                              color="primary"
                              v-show="!activarNuevaCategoriaEditar"
                              @click="activarNuevaCategoriaEditar = true"
                            >
                              <v-icon x-small>mdi-plus</v-icon>
                              Crear
                            </v-btn>

                            <v-btn
                              class="ml-1"
                              x-small
                              text
                              color="error"
                              v-show="activarNuevaCategoriaEditar"
                              @click="activarNuevaCategoriaEditar = false"
                            >
                              <v-icon x-small>mdi-close</v-icon>
                              Cancelar
                            </v-btn>

                            <v-btn
                              class="ml-1"
                              x-small
                              text
                              color="success"
                              v-show="activarNuevaCategoriaEditar"
                              @click="
                                crearCategoria(
                                  formularioEdit.categoria_nueva,
                                  formularioEdit.tipo_vehiculo
                                )
                              "
                              :loading="loader_agregar_categoria"
                            >
                              <v-icon x-small>mdi-content-save</v-icon>
                              Guardar
                            </v-btn>
                          </label>

                          <!-- Autocompletado sobre el cual se pueden seleccionar categorías -->
                          <v-autocomplete
                            :items="categoriasSelect"
                            v-if="!activarNuevaCategoriaEditar"
                            :disabled="activarNuevaCategoriaEditar"
                            v-model="formularioEdit.categoria"
                            item-text="categoria_item"
                            item-value="id_categoria_item"
                            outlined
                            hide-details
                            hide-no-data
                            placeholder="Selecciona una categoría"
                          >
                          </v-autocomplete>

                          <!-- Formulario sobre el cual podemos añadir alguna categoría, solamente
                          habilitado en caso de que se haya pulsado el botón de crear. -->
                          <v-form ref="agregar_categoria_editar">
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  v-if="activarNuevaCategoriaEditar"
                                  v-model="formularioEdit.categoria_nueva"
                                  outlined
                                  hide-details
                                  placeholder="Escribe una categoría nueva"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-select
                                  v-if="activarNuevaCategoriaEditar"
                                  v-model="formularioEdit.tipo_vehiculo"
                                  outlined
                                  hide-details
                                  placeholder="Tipo de vehículo"
                                  :items="tipos_vehiculo"
                                  item-text="tipo_vehiculo"
                                  item-value="id_tipo_vehiculo"
                                >
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-col>

                        <!-- ------------------------------------------------------- -->

                        <!-- Etiqueta sobre la cual dependiendo de si estamos creando una nueva
                        item cambiará o no. También hay botones para crear items, cancelar
                        la operación o guardar lo creado. -->
                        <v-col cols="12">
                          <label class="text-left justify-start">
                            <span v-if="!activarNuevoItemEditar"
                              >Selecciona un ítem</span
                            >
                            <span v-else>Crear nuevo ítem</span>

                            <v-btn
                              class="ml-1"
                              x-small
                              text
                              color="primary"
                              v-show="!activarNuevoItemEditar"
                              @click="activarNuevoItemEditar = true"
                            >
                              <v-icon x-small>mdi-plus</v-icon>
                              Crear
                            </v-btn>

                            <v-btn
                              class="ml-1"
                              x-small
                              text
                              color="error"
                              v-show="activarNuevoItemEditar"
                              @click="activarNuevoItemEditar = false"
                            >
                              <v-icon x-small>mdi-close</v-icon>
                              Cancelar
                            </v-btn>

                            <v-btn
                              class="ml-1"
                              x-small
                              text
                              color="success"
                              v-show="activarNuevoItemEditar"
                              @click="
                                crearItem(
                                  formularioEdit.item_nuevo,
                                  formularioEdit.codigo,
                                  formularioEdit.categoria_item
                                )
                              "
                              :loading="loader_agregar_item"
                            >
                              <v-icon x-small>mdi-content-save</v-icon>
                              Guardar
                            </v-btn>
                          </label>

                          <!-- Autocompletado sobre el cual se puede seleccionar o buscar items -->
                          <v-autocomplete
                            :items="itemsSelect"
                            v-if="!activarNuevoItemEditar"
                            :disabled="activarNuevoItemEditar"
                            v-model="formularioEdit.item"
                            item-text="item"
                            item-value="id_item"
                            outlined
                            hide-details
                            hide-no-data
                            placeholder="Selecciona un ítem"
                          >
                          </v-autocomplete>

                          <!-- Formulario sobre el cual se puede crear un item, habilitado cuando dicha
                          operación está activa. -->
                          <v-form ref=""></v-form>
                          <v-row>
                            <v-col cols="4">
                              <v-text-field
                                v-if="activarNuevoItemEditar"
                                v-model="formularioEdit.item_nuevo"
                                outlined
                                hide-details
                                placeholder="Escribe un ítem nuevo"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-select
                                v-if="activarNuevoItemEditar"
                                v-model="formularioEdit.categoria_item"
                                :items="categoriasSelect"
                                item-text="categoria_item"
                                item-value="id_categoria_item"
                                outlined
                                hide-details
                                placeholder="Categoría"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                v-if="activarNuevoItemEditar"
                                v-model="formularioEdit.codigo"
                                outlined
                                hide-details
                                placeholder="Código del ítem"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" class="mb-n8">
                          <v-text-field
                            label="Nombre del método"
                            v-model="formularioEdit.metodo"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-row>
                          <v-col cols="12" class="d-flex justify-center">
                            <label>Frecuentemente no aplica</label>
                          </v-col>
                          <v-col cols="12" class="d-flex justify-center mt-n8">
                            <v-switch
                              v-model="formularioEdit.frecuentemente_no_aplica"
                              :label="
                                formularioEdit.frecuentemente_no_aplica
                                  ? 'Si'
                                  : 'No'
                              "
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-card-text>

                    <!-- Botones para cerrar el modal o actualizar el item. -->
                    <v-btn
                      color="error"
                      small
                      @click="dialogEditar.value = false"
                      class="mb-3 mr-1"
                    >
                      <v-icon small class="mr-1">mdi-close</v-icon>
                      Cerrar
                    </v-btn>

                    <v-btn
                      color="success"
                      small
                      class="mb-3 ml-1"
                      @click="
                        editarMetodo(
                          formularioEdit.metodo,
                          formularioEdit.item,
                          formularioEdit.categoria,
                          formularioEdit.id_metodo,
                          formularioEdit.frecuentemente_no_aplica
                        );
                        dialogEditar.value = false;
                      "
                      :loading="loader_editar_metodo"
                    >
                      <v-icon small class="mr-1">mdi-check</v-icon>
                      Terminar
                    </v-btn>
                  </v-card>
                </v-form>
              </template>
            </v-dialog>

            <!-- Modal que permite quitar una pregunta de la lista, mostrando una confirmación. -->
            <v-dialog persistent width="400px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mt-2 mb-2"
                >
                  <v-icon small class="mr-1">mdi-close</v-icon>
                  Quitar
                </v-btn>
              </template>

              <template v-slot:default="dialogo_quitar_metodo">
                <v-card
                  class="text-center justify-center mx-auto"
                  width="400px"
                >
                  <v-card-title class="grey lighten-3 justify-center mb-3">
                    Quitar pregunta
                  </v-card-title>

                  <v-card-text class="justify-center text-center">
                    <div class="mb-2">
                      <v-icon size="60">mdi-help-circle-outline</v-icon>
                    </div>

                    <span
                      >¿Está seguro de que desea quitar esta pregunta de la
                      lista?</span
                    >
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      color="success"
                      small
                      @click="
                        quitarMetodo(item);
                        dialogo_quitar_metodo.value = false;
                      "
                    >
                      <v-icon small class="mr-1">mdi-check</v-icon>
                      Sí
                    </v-btn>
                    <v-btn
                      color="error"
                      small
                      @click="dialogo_quitar_metodo.value = false"
                    >
                      <v-icon small class="mr-1">mdi-close</v-icon>
                      No
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModalConfigsFormulario from "./components/ModalConfigsFormulario.vue";
import apiService from "./services/ListaDataService.js";

export default {
  data() {
    return {
      //Encabezados de la tabla
      headers: [
        {
          text: "Categoría",
          align: "start",
          sortable: false,
          value: "categoria",
        },

        {
          text: "Item",
          sortable: false,
          value: "item",
        },

        {
          text: "Método",
          sortable: false,
          value: "metodo",
        },

        {
          text: "Vehículo",
          sortable: false,
          value: "vehiculo",
        },
        {
          text: "Frecuentemente no aplica",
          align: "center",
          sortable: false,
          value: "frecuentemente_no_aplica",
        },

        {
          text: "Acciones",
          sortable: false,
          value: "acciones",
        },
      ],

      categorias: [], //Lista de categorías

      categoriasSelect: [], //Lista de categorías seleccionables

      items: [], //Lista de items

      tipos_vehiculo: [], //Lista de tipos de vehículo

      itemsSelect: [], //Lista de items seleccionables

      metodos: [], //Lista de métodos

      datosTabla: [], //Datos ya procesados que se mostrarán en la tabla

      buscadorTabla: "", //Campo de texto que buscará progresivamente coincidencias en la tabla

      //Tipos de filtros existentes
      tipos_filtro: [
        "Mostrar todos",
        "Sólo carro",
        "Sólo moto",
        "Por categoría",
        "Por ítem",
      ],

      //Objeto que almacena los datos del filtro
      filtro: {
        tipo_filtro: "",
        categoria: "",
        item: "",
      },

      activarCategorias: false, //Permite enseñar el campo de categorías en caso de que se seleccione el filtro para ello

      activarItems: false, //Permite enseñar el campo de items en caso de que se seleccione el filtro para ello

      //Objeto que almacenará todos los datos a sobreescribir en la pregunta seleccionada
      formularioEdit: {
        categoria: "",
        categoria_nueva: "",
        tipo_vehiculo: "",
        item: "",
        item_nuevo: "",
        categoria_item: "",
        codigo: "",
        metodo: "",
        id_metodo: "",
        frecuentemente_no_aplica: false,
      },

      //Objeto que almacenará los datos de una pregunta nueva.
      formularioCreate: {
        categoria: "",
        categoria_nueva: "",
        tipo_vehiculo: "",
        item: "",
        item_nuevo: "",
        categoria_item: "",
        codigo: "",
        metodo: "",
        frecuentemente_no_aplica: false,
      },

      modal_configs_formulario: false,

      /* Objeto que describe las reglas de campos ya bien sea de texto o de cualquier otro tipo. Si el valor
      coincide con esta expresión regular o función, se lanza el mensaje. */
      rules: {
        required: (v) => !!v || "Campo requerido.",
      },

      activarNuevaCategoriaAnadir: false, //Booleano que permite alternar el campo que añade categorías

      activarNuevoItemAnadir: false, //Booleano que permite alternar el campo que añade items

      activarNuevaCategoriaEditar: false, //Booleano que permite alternar el campo que añade categorías

      activarNuevoItemEditar: false, //Booleano que permite alternar el campo que añade items

      loader_agregar_categoria: false, //Spinner que aparece al crear una nueva categoría

      loader_agregar_item: false, //Spinner que aparece al crear un nuevo item

      loader_agregar_metodo: false, //Spinner que aparece al crear un método

      loader_editar_metodo: false, //Spinner que aparece al editar un método

      loader_filtro: false, //Spínner que aparece al filtrar preguntas

      loader_tabla: false, //Spinner que aparece al cargar los datos de la tabla
    };
  },
  components: {
    ModalConfigsFormulario,
  },

  computed: {
    // eslint-disable-next-line
    anchoDialogos() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return 600;

        case "lg":
          return 600;

        case "xl":
          return 600;
      }
    },
  },

  async created() {
    this.$store.state.stateLoad = true;
    await this.makeAllRequests(); //Hace todas las peticiones dentro de un solo metodo
    this.$store.state.stateLoad = false;
  },

  methods: {
    /* Método que permite obtener los datos de la pregunta para poder editarla */
    editItem(item) {
      console.log(item);
      this.formularioEdit = {
        categoria: item.id_categoria,
        categoria_nueva: "",
        tipo_vehiculo: item.vehiculo,
        item: item.id_item,
        item_nuevo: "",
        codigo: "",
        metodo: item.metodo,
        id_metodo: item.id_metodo,
        frecuentemente_no_aplica: item.frecuentemente_no_aplica,
      };
      console.log(item.frecuentemente_no_aplica);
    },

    /* Método que permite alternar entre campos de categoría e ítem cuando un filtro u otro es seleccionado */
    showFilterFields() {
      if (this.filtro.tipo_filtro.includes("categoría")) {
        this.activarCategorias = true;
        this.activarItems = false;
      } else if (this.filtro.tipo_filtro.includes("ítem")) {
        this.activarItems = true;
        this.activarCategorias = false;
      } else {
        this.activarCategorias = false;
        this.activarItems = false;
      }
    },

    /* Permite crear una categoría con su tipo de vehículo y trae las mismas de vuelta */
    crearCategoria(categoria, tipo_vehiculo) {
      this.loader_agregar_categoria = true;
      apiService
        .crearCategoria(categoria, tipo_vehiculo)
        .then(() => {
          console.log("Datos guardados!");
          this.activarNuevaCategoriaAnadir = false; //Cierra el campo de añadir categoría
          this.activarNuevaCategoriaEditar = false;
          this.loader_agregar_categoria = false;

          this.formularioCreate.categoria_nueva = ""; //Limpia los campos
          this.formularioCreate.tipo_vehiculo = "";

          apiService.getCategories().then((response) => {
            this.categorias = response.data; //Actualiza las categorías para mostrar la recién creada
            this.categoriasSelect = response.data;
          });
        })
        .catch(() => {
          console.log("Ha ocurrido un error"); //Emite el error por consola.
        });
    },

    /* Permite crear un ítem, con su código y categoría a la cual pertenece. */
    crearItem(item, codigo, categoria) {
      this.loader_agregar_item = true;

      /* Crea el ítem y cierra y limpia los campos del formulario */
      apiService.crearItem(item, codigo, categoria).then(() => {
        console.log("Datos guardados!");
        this.activarNuevoItemAnadir = false;
        this.activarNuevoItemEditar = false;
        this.loader_agregar_item = false;

        this.formularioCreate.item_nuevo = "";
        this.formularioCreate.categoria_item = "";
        this.formularioCreate.codigo = "";

        //Consigue los items otra vez para mostrar el que fue recién creado
        apiService.getItems().then((response) => {
          this.items = response.data;
          this.itemsSelect = response.data;
        });
      });
    },

    /* Crea un método con su ítem al que pertenece. */
    async crearMetodo(metodo, item, categoria , frecuentemente_no_aplica) {
      this.loader_agregar_metodo = true;

      this.loader_tabla = true;
      /* Crea el método y luego limpia el formulario, luego ejecuta todas las peticiones para confirmar el cambio */
      await apiService.crearMetodo(metodo, item, categoria, frecuentemente_no_aplica).then(async () => {
        console.log("Datos guardados!");

        this.loader_agregar_metodo = false;

        this.formularioCreate.metodo = "";
        this.formularioCreate.item = "";
        this.formularioCreate.categoria = "";

        await this.makeAllRequests();
      });

      this.loader_tabla = false;
    },

    /* Método que permite editar un método el cual fue seleccionado */
    async editarMetodo(metodo, item, categoria, id, frecuentemente_no_aplica) {
      console.log(frecuentemente_no_aplica);
      // this.loader_editar_metodo = true;

      this.loader_tabla = true;
      //Edita el método y luego limpia el formulario
      await apiService.editarMetodo(metodo, item, categoria, id,frecuentemente_no_aplica).then(async() => {
        console.log("Datos guardados!");

        this.loader_editar_metodo = false;

        this.formularioEdit.metodo = "";
        this.formularioEdit.item = "";
        this.formularioEdit.categoria = "";

        await this.makeAllRequests(); //Hace todas las peticiones de nuevo
      });

      this.loader_tabla = false;
    },

    /* Hace todas las peticiones necesarias para mostrar en la vista */
    async makeAllRequests() {
      /* Consigue los tipos de vehículo disponibles */
      await apiService.getTiposVehiculo().then((response) => {
        this.tipos_vehiculo = response.data;
      });

      //Consigue todas las categorías
      await apiService.getCategories().then((response) => {
        this.categorias = response.data;
        this.categoriasSelect = response.data;
      });

      //Consigue todos los items disponibles
      await apiService.getItems().then((response) => {
        this.items = response.data;
        this.itemsSelect = response.data;
      });

      //Consigue todos los métodos disponibles
      await apiService.getMetodos().then((response) => {
        this.metodos = response.data;

        //Luego recorre cada uno dentro del otro
        this.categorias.forEach((categoria) => {
          Object.assign(categoria, { items: [] });

          this.items.forEach((item) => {
            if (item.categoria_item == categoria.id_categoria_item) {
              Object.assign(item, { metodos: [] });

              this.metodos.forEach((metodo) => {
                if (metodo.id_item == item.id_item) {
                  this.datosTabla = [];
                  item.metodos.push(metodo);
                }
              });

              categoria.items.push(item);
            }
          });
        });

        //Se hace el mismo procedimiento pero esta vez se usa la variable datosTabla para mostrar los datos
        //de manera más legible
        this.categorias.forEach((categoria) => {
          categoria.items.forEach((item) => {
            item.metodos.forEach((metodo) => {
              this.datosTabla.push({
                id_categoria: categoria.id_categoria_item,
                categoria: categoria.categoria_item,
                id_item: item.id_item,
                item: item.item,
                id_metodo: metodo.id_metodo,
                metodo: metodo.metodo,
                vehiculo:
                  categoria.tipo_vehiculo == 1
                    ? "Carro"
                    : categoria.tipo_vehiculo == 2
                    ? "Motocicleta"
                    : "Otros",
                frecuentemente_no_aplica: metodo.frecuentemente_no_aplica,
              });
            });
          });
        });
      });
    },

    /* Filtra con base al tipo de filtro que se haya suministrado en el formulario */
    async filter() {
      if (this.$refs.formularioFiltros.validate()) {
        this.loader_filtro = true;
        this.loader_tabla = true;
        switch (this.filtro.tipo_filtro) {
          case "Mostrar todos":
            /* En el caso de que se quiera mostrar todos, se consiguen categorías, items y métodos y se
            asignan a los datos de la tabla una vez estén debidamente filtrados y procesados. */
            await apiService.getCategories().then((response) => {
              this.categorias = response.data;
            });

            await apiService.getItems().then((response) => {
              this.items = response.data;
            });

            await apiService.getMetodos().then((response) => {
              this.datosTabla = [];
              this.metodos = response.data;

              this.categorias.forEach((categoria) => {
                Object.assign(categoria, { items: [] });

                this.items.forEach((item) => {
                  if (item.categoria_item == categoria.id_categoria_item) {
                    Object.assign(item, { metodos: [] });

                    this.metodos.forEach((metodo) => {
                      if (metodo.id_item == item.id_item) {
                        item.metodos.push(metodo);
                      }
                    });

                    categoria.items.push(item);
                  }
                });
              });
            });

            this.categorias.forEach((categoria) => {
              categoria.items.forEach((item) => {
                item.metodos.forEach((metodo) => {
                  this.datosTabla.push({
                    id_categoria: categoria.id_categoria_item,
                    categoria: categoria.categoria_item,
                    id_item: item.id_item,
                    item: item.item,
                    metodo: metodo.metodo,
                    vehiculo:
                      categoria.tipo_vehiculo == 1
                        ? "Carro"
                        : categoria.tipo_vehiculo == 2
                        ? "Motocicleta"
                        : "Otros",
                    frecuentemente_no_aplica: metodo.frecuentemente_no_aplica,
                  });
                });
              });
            });

            this.dialogFiltro = false;
            this.loader_filtro = false;
            this.loader_tabla = false;
            break;

          case "Sólo carro":
            /* En el caso de que se quiera ver sólo las preguntas pertenecientes al formulario de carro, se
            consiguen a las categorías pertenecientes a este tipo de vehículo y hace el mismo procedimiento
            de filtración. */
            await apiService.filtroCarro().then((response) => {
              this.categorias = response.data;
            });

            await apiService.getItems().then((response) => {
              this.items = response.data;
            });

            await apiService.getMetodos().then((response) => {
              this.datosTabla = [];
              this.metodos = response.data;

              this.categorias.forEach((categoria) => {
                Object.assign(categoria, { items: [] });

                this.items.forEach((item) => {
                  if (item.categoria_item == categoria.id_categoria_item) {
                    Object.assign(item, { metodos: [] });

                    this.metodos.forEach((metodo) => {
                      if (metodo.id_item == item.id_item) {
                        item.metodos.push(metodo);
                      }
                    });

                    categoria.items.push(item);
                  }
                });
              });
            });

            this.categorias.forEach((categoria) => {
              categoria.items.forEach((item) => {
                item.metodos.forEach((metodo) => {
                  this.datosTabla.push({
                    id_categoria: categoria.id_categoria_item,
                    categoria: categoria.categoria_item,
                    id_item: item.id_item,
                    item: item.item,
                    metodo: metodo.metodo,
                    vehiculo:
                      categoria.tipo_vehiculo == 1
                        ? "Carro"
                        : categoria.tipo_vehiculo == 2
                        ? "Motocicleta"
                        : "Otros",
                    frecuentemente_no_aplica: metodo.frecuentemente_no_aplica,
                  });
                });
              });
            });

            this.dialogFiltro = false;
            this.loader_filtro = false;
            this.loader_tabla = false;
            break;

          /* En caso de que se quiera ver las preguntas del formulario de moto, se traen las categorías
          pertenecientes a este tipo de vehículo y se hace el mismo procedimiento de filtración. */
          case "Sólo moto":
            await apiService.filtroMoto().then((response) => {
              this.categorias = response.data;
            });

            await apiService.getItems().then((response) => {
              this.items = response.data;
            });

            await apiService.getMetodos().then((response) => {
              this.datosTabla = [];
              this.metodos = response.data;

              this.categorias.forEach((categoria) => {
                Object.assign(categoria, { items: [] });

                this.items.forEach((item) => {
                  if (item.categoria_item == categoria.id_categoria_item) {
                    Object.assign(item, { metodos: [] });

                    this.metodos.forEach((metodo) => {
                      if (metodo.id_item == item.id_item) {
                        item.metodos.push(metodo);
                      }
                    });

                    categoria.items.push(item);
                  }
                });
              });
            });

            this.categorias.forEach((categoria) => {
              categoria.items.forEach((item) => {
                item.metodos.forEach((metodo) => {
                  this.datosTabla.push({
                    id_categoria: categoria.id_categoria_item,
                    categoria: categoria.categoria_item,
                    id_item: item.id_item,
                    item: item.item,
                    metodo: metodo.metodo,
                    vehiculo:
                      categoria.tipo_vehiculo == 1
                        ? "Carro"
                        : categoria.tipo_vehiculo == 2
                        ? "Motocicleta"
                        : "Otros",
                    frecuentemente_no_aplica: metodo.frecuentemente_no_aplica,
                  });
                });
              });
            });

            this.dialogFiltro = false;
            this.loader_filtro = false;
            this.loader_tabla = false;
            break;

          /* En caso de que sea bajo una categoría específica, se trae junto con el ID de categoría los
          items y preguntas pertenecientes a esa categoría. Luego se hace el mismo proceso de filtración. */
          case "Por categoría":
            await apiService
              .filtroCategoria(this.filtro.categoria)
              .then((response) => {
                this.categorias = response.data;
              });

            await apiService.getItems().then((response) => {
              this.items = response.data;
            });

            await apiService.getMetodos().then((response) => {
              this.datosTabla = [];
              this.metodos = response.data;

              this.categorias.forEach((categoria) => {
                Object.assign(categoria, { items: [] });

                this.items.forEach((item) => {
                  if (item.categoria_item == categoria.id_categoria_item) {
                    Object.assign(item, { metodos: [] });

                    this.metodos.forEach((metodo) => {
                      if (metodo.id_item == item.id_item) {
                        item.metodos.push(metodo);
                      }
                    });

                    categoria.items.push(item);
                  }
                });
              });
            });

            this.categorias.forEach((categoria) => {
              categoria.items.forEach((item) => {
                item.metodos.forEach((metodo) => {
                  this.datosTabla.push({
                    id_categoria: categoria.id_categoria_item,
                    categoria: categoria.categoria_item,
                    id_item: item.id_item,
                    item: item.item,
                    metodo: metodo.metodo,
                    vehiculo:
                      categoria.tipo_vehiculo == 1
                        ? "Carro"
                        : categoria.tipo_vehiculo == 2
                        ? "Motocicleta"
                        : "Otros",
                    frecuentemente_no_aplica: metodo.frecuentemente_no_aplica,
                  });
                });
              });
            });

            this.dialogFiltro = false;
            this.loader_filtro = false;
            this.loader_tabla = false;
            break;

          /* En caso de que sea un ítem en especifico, se usa el ID del ítem para poder traer las preguntas
          específicas a este ítem, luego se hace el proceso de filtración. */
          case "Por ítem":
            await apiService.filtroItem(this.filtro.item).then((response) => {
              this.items = response.data;
            });

            await apiService.getCategories().then((response) => {
              this.categorias = response.data;
            });

            await apiService.getMetodos().then((response) => {
              this.datosTabla = [];
              this.metodos = response.data;

              this.categorias.forEach((categoria) => {
                Object.assign(categoria, { items: [] });

                this.items.forEach((item) => {
                  if (item.categoria_item == categoria.id_categoria_item) {
                    Object.assign(item, { metodos: [] });

                    this.metodos.forEach((metodo) => {
                      if (metodo.id_item == item.id_item) {
                        item.metodos.push(metodo);
                      }
                    });

                    categoria.items.push(item);
                  }
                });
              });
            });

            this.categorias.forEach((categoria) => {
              categoria.items.forEach((item) => {
                item.metodos.forEach((metodo) => {
                  this.datosTabla.push({
                    id_categoria: categoria.id_categoria_item,
                    categoria: categoria.categoria_item,
                    id_item: item.id_item,
                    item: item.item,
                    metodo: metodo.metodo,
                    vehiculo:
                      categoria.tipo_vehiculo == 1
                        ? "Carro"
                        : categoria.tipo_vehiculo == 2
                        ? "Motocicleta"
                        : "Otros",
                    frecuentemente_no_aplica: metodo.frecuentemente_no_aplica,
                  });
                });
              });
            });

            this.dialogFiltro = false;
            this.loader_filtro = false;
            this.loader_tabla = false;
            break;
        }
      }
    },

    /* Quita una pregunta de la lista y hace de nuevo las peticiones */
    async quitarMetodo(item) {
      this.loader_tabla = true;
      await apiService
        .quitarMetodo(item.id_metodo)
        .then(() => {
          console.log("Datos guardados!");

          this.makeAllRequests();
        })
        .catch((error) => {
          console.log(error);
        });

      this.loader_tabla = false;
    },
  },
};
</script>

<style scoped>
>>> .v-data-table__wrapper {
  background-color: rgba(236, 236, 236, 0.8);
  border-radius: 10px;
}

>>> td {
  border-bottom: 1px solid #adadad;
}

>>> .v-data-table__mobile-table-row {
  margin: 20px;
  border: 1px solid #adadad;
  border-radius: 10px;
}

>>> .v-data-table__mobile-row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
