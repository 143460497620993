<template>
  <v-card>
    <v-toolbar dark color="primary" height="40">
      <v-spacer></v-spacer>
      <v-toolbar-title>Configuraciones</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <div v-if="!cargando_datos_vista">
        <v-col class="d-flex justify-center"
          ><v-checkbox
            v-model="select_all_campos"
            label="Habilitar All"
          ></v-checkbox
        ></v-col>
      </div>
      <v-col
        v-else
        cols="12"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 130px;
        "
      >
        <v-spacer></v-spacer>
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-spacer></v-spacer>
      </v-col>
    </v-card-text>

    <v-card-actions class="mt-n5 justify-end" v-if="!cargando_datos_vista">
      <v-btn
        color="red"
        class="white--text"
        :disabled="guardando_cambios"
        @click="$emit('cerrar-modal')"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="success"
        :loading="guardando_cambios"
        @click="guardarCambios"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import ListaDataService from "../services/ListaDataService";
import formDataService from "@/modules/formulario/services/FormDataServiceCarros.js";
export default {
  data: () => ({
    cargando_datos_vista: true,
    guardando_cambios: false,

    select_all_campos: false,
  }),
  async created() {
    this.cargando_datos_vista = true;
    //Consigue todos las configs disponibles mediante request
    await formDataService.getConfigs().then((response) => {
      //Si el config tiene algo que ver con este proyecto (incluye el prefijo "sensorial") y si el estado está activo
      if (
        parseInt(response.data.resultado_config[0].valor) == 1 &&
        response.data.resultado_conteo == 1
      ) {
        //El checkbox se vuelve activo
        this.select_all_campos = true;
      } else {
        //De lo contrario, se desactiva
        this.select_all_campos = false;
      }
    });
    this.cargando_datos_vista = false;
  },

  methods: {
    async guardarCambios() {
      this.guardando_cambios = true;
      await ListaDataService.guardarCambiosConfigs({
        select_all_campos: this.select_all_campos,
      }).then((response) => {
        this.$swal.fire({
          title: "Configs Guardados",
          icon: "success",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          position: "center",
          timer: 2000,
          showConfirmButton: false,
          confirmButtonText: "Ok",
        }).then(()=>{
            this.$emit('cerrar-modal');
        })

      });
      this.guardando_cambios = false;
    },
  },
};
</script>
<style lang=""></style>
